import { Container } from '@vli/locomotive-ds/dist';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Form from 'components/contexts/user/form';
import Loading from 'components/core/Loading';
import { Content } from 'components/system/interface';
import useDimensions from 'hooks/useDimensions';

import { ProfileActions } from 'store/profile/profile.ducks';
import { SegmentActions } from 'store/segment/segment.ducks';
import { UserActions } from 'store/user/user.ducks';

import { getDataSend } from 'lib/contexts/user';
import { isPending } from 'lib/core/request';

import * as ROUTES from 'constants/urls';

const EditUser = () => {
  const dimensions = useDimensions();
  const { id } = useParams();

  const breadcrumb = [
    {
      text: 'Usuários',
      href: `#${ROUTES.USER_LIST}`,
    },
    {
      text: 'Editar Usuário',
      href: `#${ROUTES.EDIT_USER(id)}`,
    },
  ];

  const dispatch = useDispatch();

  const { user: editUser, usersRequests } = useSelector(({ user }) => user);
  const { data: profiles, profilesRequests } = useSelector(
    ({ profile }) => profile,
  );

  const { segmentProfileList: segmentList, segmentRequests } = useSelector(
    ({ segment }) => segment,
  );

  useEffect(() => {
    dispatch(UserActions.getUser(id));
    dispatch(ProfileActions.fetchProfiles());
    dispatch(SegmentActions.listSegmentActive());
  }, [dispatch, id]);

  const onSubmit = (values) => {
    const data = getDataSend(values);
    dispatch(UserActions.updateUser({ ...data, id }));
  };

  const isLoading =
    isPending(segmentRequests.LIST_SEGMENT_ACTIVE) ||
    isPending(profilesRequests.FETCH_PROFILES) ||
    isPending(usersRequests.UPDATE_USER);

  return (
    <Content data-testid="content" height={dimensions.height?.toString()}>
      {isLoading && <Loading />}

      {!isLoading && (
        <Container>
          <Form
            editUser={editUser}
            method="edit"
            breadcrumb={breadcrumb}
            profiles={profiles}
            segments={segmentList}
            onSubmit={onSubmit}
          />
        </Container>
      )}
    </Content>
  );
};

export default EditUser;
