import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Accordion,
    Button,
    Container,
    Flex,
    Header,
} from '@vli/locomotive-ds/dist';

import Loading from 'components/core/Loading';
import { Content } from 'components/system/interface';

import ParametersField from 'components/contexts/systemParameters/ParameterField';

import { SystemParametersActions } from 'store/systemParameters/systemParameters.ducks';

import { separateByCategory } from 'lib/contexts/systemParameters';
import { isPending } from 'lib/core/request';

import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';

import * as S from './SystemParametersStyles';

const breadcrumb = [
  {
    text: 'Gestão',
    href: null,
  },
  {
    text: 'Parâmetros',
    href: `#${ROUTES.SYSTEM_PARAMETERS}`,
  },
];

const SystemParameters = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const { parameters, systemParametersRequest } = useSelector(
    ({ systemParameters }) => systemParameters,
  );

  const [parametersToSend, setParametersToSend] = useState([]);

  useEffect(() => {
    dispatch(SystemParametersActions.systemParametersList());
  }, [dispatch]);

  const isLoading =
    isPending(systemParametersRequest.SYSTEM_PARAMETERS_LIST) ||
    isPending(systemParametersRequest.SYSTEM_PARAMETERS_UPDATE);

  const parametersByCategory = parameters.length
    ? separateByCategory(parameters)
    : [];

  const handleOnChangeField = (id, value) => {
    const foundParameterIndex = parametersToSend.findIndex(
      (parameter) => parameter.id === id,
    );

    if (foundParameterIndex > -1) {
      const newParameters = [...parametersToSend];
      newParameters[foundParameterIndex].value = value;

      setParametersToSend(newParameters);
    } else {
      setParametersToSend((prevState) => [...prevState, { id, value }]);
    }
  };

  const handleUpdateParameters = () => {
    dispatch(SystemParametersActions.systemParametersUpdate(parametersToSend));
    setParametersToSend([]);
  };

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Parâmetros" />

          <Button
            variant="primary"
            name="Atualizar parâmetros"
            type="button"
            key="button-update-parameters"
            onClick={handleUpdateParameters}
          />
        </Flex>

        {isLoading ? (
          <Loading />
        ) : (
          <S.ParametersContainer>
            {parametersByCategory?.map(([key, fields]) => (
              <Accordion title={key} key={key} width="100%" open>
                <S.Fields>
                  {fields.map((field) => (
                    <ParametersField
                      {...field}
                      handleOnChangeField={handleOnChangeField}
                      key={field.id}
                    />
                  ))}
                </S.Fields>
              </Accordion>
            ))}
          </S.ParametersContainer>
        )}
      </Container>
    </Content>
  );
};

export default SystemParameters;
