import { Container, Flex, Header } from '@vli/locomotive-ds/dist';
import StatusContent from 'components/contexts/status';
import { Content } from 'components/system/interface';
import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';
import React from 'react';

const StatusApplication = () => {
  const dimensions = useDimensions();
  const breadcrumb = [
    {
      text: 'Status da aplicação',
      href: `#${ROUTES.STATUS}`,
    },
  ];
  return (
    <Content data-testid="content" height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Status da aplicação" />
        </Flex>
        <StatusContent />
      </Container>
    </Content>
  );
};

export default StatusApplication;
