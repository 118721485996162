import css from '@styled-system/css';
import styled from 'styled-components';
import { color, compose, space, typography, variant } from 'styled-system';

import theme from '../../tokens';

export const HeadingTag = styled('')(
  ({ as }) => {
    switch (as) {
      case 'h2':
        return css({ font: theme.fonts.headingRegularH2 });
      case 'h3':
        return css({ font: theme.fonts.headingRegularH3 });
      case 'h4':
        return css({ font: theme.fonts.headingRegularH4 });
      case 'h5':
        return css({ font: theme.fonts.headingRegularH5 });
      case 'h6':
        return css({ font: theme.fonts.headingRegularH6 });
      default:
        return css({ font: theme.fonts.headingRegularH1 });
    }
  },
  color({ color: color || theme.colors.gray500 }),
  variant({
    variants: {
      bold: {
        fontWeight: 'bold',
      },
    },
  }),
  compose(typography, color, space),
);
