import { Button, Container, Flex, Header } from '@vli/locomotive-ds/dist';
import FilterResendItems from 'components/contexts/resendItems/FilterResendItems';
import ResendItems from 'components/contexts/resendItems/ResendItems';
import { Content } from 'components/system/interface';
import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResendItemsActions } from 'store/resendItems/resendItems.ducks';

const breadcrumb = [
  {
    text: 'Reenvio de itens',
    href: `#${ROUTES.RESEND_ITEMS}`,
  },
];

const ResendItemsList = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const { resendItemsList } = useSelector(({ resendItems }) => resendItems);

  const resendItems = () => {
    const itemsCode = resendItemsList.items.map((item) => item.historyId);
    const payload = { IdHistorys: itemsCode };

    dispatch(ResendItemsActions.resendItemRequest(payload));
  };

  useEffect(() => {
    dispatch(ResendItemsActions.resendItemsListRequest(''));
  }, [dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Reenvio de itens" />
          <Button name="Reenviar em massa" onClick={resendItems} />
        </Flex>
        <Flex justifyContent="flex-end">
          <FilterResendItems />
        </Flex>
        <ResendItems />
      </Container>
    </Content>
  );
};

export default ResendItemsList;
