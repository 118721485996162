import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({
  title,
  children,
  place,
  offset,
  multiline,
  id,
  clickable,
  html,
  ...attrs
}) => (
  <div className="tooltip-wrapper" data-testid="ds-tooltip">
    <span data-tip={title} data-for={id}>
      {children}
    </span>
    <ReactTooltip
      place={place}
      multiline={multiline}
      offset={offset}
      id={id}
      clickable={clickable}
      html={html}
      {...attrs}
    />
  </div>
);

Tooltip.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
  place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  offset: PropTypes.oneOf({ top: 10, left: 10 }),
  multiline: PropTypes.bool,
  clickable: PropTypes.bool,
  html: PropTypes.bool,
};

Tooltip.defaultProps = {
  id: 'tool',
  title: '',
  place: 'top',
  offset: {},
  multiline: false,
  clickable: false,
  html: false,
};

export default Tooltip;
