import {
    Button,
    Container,
    EmptyState,
    Flex,
    Header,
} from '@vli/locomotive-ds/dist';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IndicesHistoryChart from 'components/contexts/indices/IndicesHistoryChart';
import IndicesHistoryFilter from 'components/contexts/indices/IndicesHistoryFilter';
import IndicesHistoryTable from 'components/contexts/indices/IndicesHistoryTable';
import Loading from 'components/core/Loading';
import Spacing from 'components/core/Spacing';
import { Content } from 'components/system/interface';
import { IndicesHistoryActions } from 'store/indicesHistory/indicesHistory.ducks';

import { isFinished, isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import * as ROUTES from 'constants/urls';

const Indices = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const breadcrumb = [
    {
      text: 'Índices Macroeconômicos',
      href: `#${ROUTES.MACROECONOMIC_INDICES_HISTORY}`,
    },
  ];

  useEffect(() => {
    dispatch(IndicesHistoryActions.listIndicesHistoryWithInflation());
  }, [dispatch]);

  const {
    indicesHistoryRequests,
    indicesHistoryList: { list },
    filter,
  } = useSelector(({ indicesHistory }) => indicesHistory);

  const { indicesRequests } = useSelector(({ indices }) => indices);

  const isLoading =
    isPending(indicesHistoryRequests.LIST_INDICES_HISTORY) ||
    isPending(indicesRequests.LIST_SUPPLIERS) ||
    isPending(indicesHistoryRequests.DOWNLOAD_REPORT);

  const handleDownloadButton = () => {
    let payload = {};
    const indexIds = [];

    if (filter) {
      Object.entries(filter).forEach(([field, value]) => {
        if (field.includes('inflationary')) {
          indexIds.push(value);
        }

        if (field.includes('suppliers')) {
          value.forEach((supplier) => {
            indexIds.push(supplier.value.id);
          });
        }
      });
    }

    if (filter?.startPeriod) {
      payload = {
        ...payload,
        startPeriod: filter.startPeriod,
      };
    }

    if (filter?.endPeriod) {
      payload = {
        ...payload,
        endPeriod: filter.endPeriod,
      };
    }

    payload = {
      ...payload,
      indexIds,
    };

    dispatch(IndicesHistoryActions.downloadReport(payload));
  };

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header
            bread={breadcrumb}
            tag="header"
            title="Índices Macroeconômicos"
          />

          <Flex alignItems="center">
            <Button
              data-testid="report-extraction-button"
              name="Download de relatório"
              variant="primary"
              onClick={handleDownloadButton}
            />

            <IndicesHistoryFilter />
          </Flex>
        </Flex>

        {isLoading && <Loading />}

        {list.months.length > 0 &&
          !isLoading &&
          isFinished(indicesHistoryRequests.LIST_INDICES_HISTORY) && (
            <>
              <Flex>
                <IndicesHistoryChart />
              </Flex>
              <Flex>
                <IndicesHistoryTable />
              </Flex>
            </>
          )}

        {list?.months?.length === 0 && !isLoading && (
          <Spacing>
            <EmptyState description="Não há registros de índices para este critério, tente uma nova busca." />
          </Spacing>
        )}
      </Container>
    </Content>
  );
};

export default Indices;
