import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ChevronRightIcon from 'assets/system/svgs/chevron/right.svg';

import Icon from '../../../core/icon';

import { Item } from '../styles';

const BreadcrumbItem = ({ className, active, tag, children, ...attrs }) => {
  const classes = classNames(className, active && 'active', 'breadcrumb-item');
  return (
    <>
      <Icon src={ChevronRightIcon} />
      <Item
        {...attrs}
        as={tag}
        className={classes}
        aria-current={active ? 'page' : undefined}
      >
        {children}
      </Item>
    </>
  );
};

BreadcrumbItem.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
};

BreadcrumbItem.defaultProps = {
  className: '',
  active: false,
  tag: 'a',
  children: '',
};

export default BreadcrumbItem;
