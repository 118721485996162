import PropTypes from 'prop-types';
import React from 'react';

import { RowStyle } from './styles';

const Row = ({ children, ...attrs }) => (
  <RowStyle {...attrs} data-testid="ds-row">
    {children}
  </RowStyle>
);

Row.propTypes = {
  children: PropTypes.node,
};

Row.defaultProps = {
  children: '',
};

export default Row;
