import { Container, Flex, Header } from '@vli/locomotive-ds/dist';
import React from 'react';

import Search from 'components/core/Search';
import { Content } from 'components/system/interface';

import ReportContractsList from 'components/contexts/reportContractItems/ReportContractsList/ReportContractsList';

import useDimensions from 'hooks/useDimensions';

import { getFilterParams } from 'lib/contexts/reportContractItems';
import { useDispatch } from 'react-redux';
import { ReportContractItemsActions } from 'store/reportContractItems/reportContractItems.ducks';

const breadcrumb = [
  {
    text: 'Relatórios',
    href: null,
  },
];

const ReportContractItemsList = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const listReportContractItems = (payload) => {
    const params = getFilterParams(payload);
    dispatch(
      ReportContractItemsActions.listReportContractItemsFilter(params, payload),
    );
  };

  const onChangeContract = (contractPayload) => {
    listReportContractItems(contractPayload);
  };

  const onChangeClient = (clientPayload) => {
    listReportContractItems(clientPayload);
  };

  return (
    <Content height={dimensions.height} data-testid="contract-report-list-page">
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Relatórios" />
        </Flex>
        <Search
          onChangeClient={onChangeClient}
          onChangeContract={onChangeContract}
        />
        <ReportContractsList />
      </Container>
    </Content>
  );
};

export default ReportContractItemsList;
