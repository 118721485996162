import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { HeadingTag } from './styles';

const Heading = ({ tag, variant, size, ...attrs }) => {
  const manageVariant = classNames(variant).split(' ');
  return (
    <HeadingTag
      as={tag}
      variant={manageVariant}
      {...attrs}
      style={{ fontSize: size }}
      data-testid="ds-heading"
    />
  );
};

Heading.propTypes = {
  variant: PropTypes.array,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: PropTypes.number,
};

Heading.defaultProps = {
  variant: [],
  className: '',
  tag: 'h1',
  size: null,
};

export default Heading;
