export default {
  colors: {
    accentPrimaryDark: '#0074C0',
    accentPrimaryLight: '#E6F1F9',
    accentSecondaryDark: '#F37A19',
    accentSecondaryLight: '#FFEEE0',
    grayWhite: '#FFFFFF',
    grayBlack: '#000000',
    gray600: '#999999',
    gray500: '#333333',
    gray400: '#666666',
    gray300: '#949494',
    gray200: '#CCCCCC',
    gray100: '#F5F5F5',
    green500: '#16411A',
    green400: '#27752D',
    green300: '#38A740',
    green200: '#8DD994',
    green100: '#E7F7E8',
    lime500: '#4C4F00',
    lime400: '#8F9400',
    lime300: '#D4D935',
    lime200: '#EFF291',
    lime100: '#F9FADC',
    yellow500: '#523600',
    yellow400: '#946300',
    yellow300: '#FFB61F',
    yellow200: '#FFDA8F',
    yellow100: '#FFF5E0',
    orange500: '#522500',
    orange400: '#944300',
    orange300: '#F37A19',
    orange200: '#FFC18F',
    orange100: '#FFEEE0',
    red500: '#580E0F',
    red400: '#A32626',
    red300: '#E24445',
    red200: '#F4B9B9',
    red100: '#FCEDEE',
    pink500: '#55113E',
    pink400: '#992370',
    pink300: '#DA48AA',
    pink200: '#F0BCDF',
    pink100: '#FBEEF7',
    violet500: '#282569',
    violet400: '#3A3699',
    violet300: '#564FF0',
    violet200: '#C8C6F7',
    violet100: '#EAE9FF',
    blue500: '#0C374D',
    blue400: '#166188',
    blue300: '#1D9BDB',
    blue200: '#B0DCF2',
    blue100: '#E5F3FB',
    steel500: '#324044',
    steel400: '#586E75',
    steel300: '#7898A1',
    steel200: '#C3D0D4',
    steel100: '#EBF1F2',
  },
  fonts: {
    textRegularSmall:
      '500 12px/16px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    textRegularMedium:
      '500 14px/20px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    textRegularLarge:
      '500 16px/24px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    textBoldSmall:
      '700 12px/16px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    textBoldMedium:
      '700 14px/20px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    textBoldLarge:
      '700 16px/24px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingRegularH1:
      '500 28px/40px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingRegularH2:
      '500 24px/36px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingRegularH3:
      '500 22px/32px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingRegularH4:
      '500 20px/32px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingRegularH5:
      '500 18px/28px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingRegularH6:
      '500 16px/24px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingBoldH1:
      '700 28px/40px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingBoldH2:
      '700 24px/36px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingBoldH3:
      '700 22px/32px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingBoldH4:
      '700 20px/32px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingBoldH5:
      '700 18px/28px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headingBoldH6:
      '700 16px/24px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
  },
  media: {
    xs: '@media (max-width: 480px)',
    sm: '@media (max-width: 768px)',
    md: '@media (max-width: 1024px)',
    lg: '@media (max-width: 1280px)',
    xl: '@media (max-width: 1600px)',
    xxl: '@media (max-width: 1920px)',
    breakpoints: {
      xs: 480,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1600,
      xxl: 1920,
    },
    mobile: '@media only screen and (max-width: 480px)',
    tablet: '@media (min-width: 480px) and (max-width: 768px)',
    desktop: '@media (min-width: 768px)',
  },
  spacing: {
    xss: '4px',
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '40px',
    xxl: '64px',
  },
  breakpoints: ['480px', '768px', '1024px', '1280px', '1600px', '1920px'],
  effects: {
    cardShadow: '0px 2px 3px rgba(0, 0, 0, 0.12)',
    dialogShadow: '0px 2px 17px rgba(0, 0, 0, 0.167395)',
    buttonClick: '0px 3px 3px rgba(0, 0, 0, 0.08)',
    buttonHover: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    buttonFocus: 'solid 2px #D7E5F4',
    contextMenu: '-2px 0px 8px rgba(0, 0, 0, 0.12)',
  },
};
