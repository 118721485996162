import { Container, Flex, Header } from '@vli/locomotive-ds/dist';
import React from 'react';

import { Content } from 'components/system/interface';

import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';

import Register from 'components/contexts/contractUpload/Register';
import Spreadsheets from 'components/contexts/contractUpload/Spreadsheets';

import * as S from './ContractUploadStyles';

const ContractUpload = () => {
  const dimensions = useDimensions();

  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
    {
      text: 'Upload de contrato',
      href: null,
    },
  ];

  return (
    <Content data-testid="upload-contract-page-test" height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Upload de contrato" />
        </Flex>

        <S.Sections>
          <Spreadsheets />

          <Register />
        </S.Sections>
      </Container>
    </Content>
  );
};

export default ContractUpload;
