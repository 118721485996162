import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, space } from 'styled-system';

import theme from '../../tokens';

export const Container = styled('')(
  css({
    display: 'flex',
  }),
  compose(space),
);

export const ListTag = styled('')(
  css({
    m: 0,
    p: 0,
    display: 'flex',
    listStyle: 'none',
    alignItems: 'center',
    '> :nth-child(n)': {
      color: theme.colors.gray300,
    },
    span: {
      cursor: 'pointer',
    },
    a: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export const Item = styled('')(
  css({
    font: theme.fonts.textRegularSmall,
    textTransform: 'capitalize',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.colors.gray300,
    textDecoration: 'none',
  }),
);

export const BreadStyle = styled('span')(
  css({
    font: theme.fonts.textRegularMedium,
    margin: '4px',
    '&:after': {
      content: "'/'",
      position: 'relative',
      left: '4px',
    },
    '&:last-of-type': {
      marginRight: '0',
      '&:after': {
        content: "''",
      },
    },
  }),
);
