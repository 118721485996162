import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';

import theme from '../../../tokens';

const { md, xs } = theme.media;

export const MenuHeaderStyle = styled('div')(
  css({
    display: 'flex',
    height: '64px',
    maxWidth: '200px',
    [xs]: {
      maxWidth: '100%',
    },
    alignItems: 'center',
    background: theme.colors.grayWhite,
    '& > svg': {
      visibility: 'visible',
      minWidth: '64px',
      width: 'auto',
    },
    '& > span': {
      font: theme.fonts.textRegularSmall,
      color: theme.colors.accentPrimaryDark,
      whiteSpace: 'pre-line',
      position: 'relative',
      paddingLeft: '5px',
      [md]: {
        visibility: 'hidden',
      },
      [xs]: {
        visibility: 'visible',
      },
    },
    '&.expanded': {
      '& > span': {
        visibility: 'visible',
      },
    },
    '& > button': {
      border: '0',
      background: 'transparent',
      position: 'absolute',
      right: '19px',
      top: '16px',
      zIndex: '1',
      display: 'none',
      [xs]: {
        display: 'block',
      },
    },
    '&.collapse': {
      '& > span': {
        display: 'none',
      },
    },
  }),
  compose(space, flexbox, layout),
);
