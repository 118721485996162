import PropTypes from 'prop-types';
import React from 'react';

import { MenuListStyle } from './styles';

const MenuList = ({ children, subList, ...attrs }) => (
  <MenuListStyle className={subList && 'sub-list'} {...attrs}>
    {children}
  </MenuListStyle>
);

MenuList.propTypes = {
  children: PropTypes.node,
  subList: PropTypes.bool,
};

MenuList.defaultProps = {
  children: '',
  subList: false,
};

export default MenuList;
