import React, { forwardRef } from 'react';
import { FlexStyle } from './styles';

const Flex = forwardRef(
  ({ children = '', spaceChildren, flexDirection, ...attrs }, ref) => (
    <FlexStyle
      ref={ref}
      spaceChildren={spaceChildren}
      flexDirection={flexDirection}
      {...attrs}
      data-testid="ds-core-flex"
    >
      {children}
    </FlexStyle>
  ),
);

export default Flex;
