import { Container, Flex, Header } from '@vli/locomotive-ds/dist';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Content } from 'components/system/interface';

import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';

import { AttendantActions } from 'store/attendant/attendant.ducks';
import { CustomerActions } from 'store/customer/customer.ducks';
import { UserActions } from 'store/user/user.ducks';

import FilterRequests from 'components/contexts/requestsList/FilterRequests';
import RequestsTable from 'components/contexts/requestsList/RequestTable';

const RequestsList = () => {
  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
    {
      text: 'Solicitações',
      href: `#${ROUTES.REQUESTS_LIST}`,
    },
  ];

  const dimensions = useDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CustomerActions.listProposalsCustomer());
    dispatch(UserActions.listFormalizationsUser());
    dispatch(AttendantActions.listAttendant());
  }, [dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex>
          <Header
            bread={breadcrumb}
            tag="header"
            title="Solicitações de Formalização"
          />
        </Flex>

        <FilterRequests data-testid="request-list-filter-test" />

        <RequestsTable data-testid="request-list-table-test" />
      </Container>
    </Content>
  );
};

export default RequestsList;
