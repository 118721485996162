import PropTypes from 'prop-types';
import React from 'react';

import Heading from '../../typography/heading';
import Breadcrumb from '../breadcrumb';

import { HeaderActionsStyle, HeaderContainerStyle } from './styles';

const Header = ({ tag, title, bread, children }) => (
  <HeaderContainerStyle as={tag} id="header" data-testid="ds-header">
    <Heading tag="h1" variant={[{ bold: true }]}>
      {title}
    </Heading>
    {bread && <Breadcrumb bread={bread} />}
    <HeaderActionsStyle>{children}</HeaderActionsStyle>
  </HeaderContainerStyle>
);

Header.propTypes = {
  bread: PropTypes.string,
  title: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
};

Header.defaultProps = {
  bread: '',
  tag: 'header',
  children: '',
};

export default Header;
