import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import ChevronUpIcon from 'assets/system/svgs/chevron/up.svg';

import Icon from '../../../core/icon';
import Tooltip from '../../../core/tooltip';
import theme from '../../../tokens';
import Text from '../../../typography/text';

import MenuContext from '../context';

import { MenuItemStyle } from './styles';

const MenuItem = ({
  children,
  active,
  icon,
  tooltipTitle,
  subItem,
  title,
  secondaryMenu,
  ...attrs
}) => {
  const [isSubOpen, setSubOpen] = useState(false);
  const { isCollapse, toggleCollapse } = useContext(MenuContext);

  useEffect(() => {
    if (isCollapse) {
      setSubOpen(false);
    }
  }, [isCollapse]);

  const toggleSubMenu = () => {
    if (isCollapse) {
      toggleCollapse();
      setSubOpen(true);
    } else {
      setSubOpen(!isSubOpen);
    }
  };

  return (
    <MenuItemStyle
      className={clsx(
        active && 'active',
        isSubOpen && 'open-sub-item',
        subItem && 'sub-item',
      )}
      {...attrs}
    >
      <Tooltip title={tooltipTitle} className="tooltip" id={tooltipTitle}>
        {subItem ? (
          <>
            <button
              type="button"
              onClick={() => toggleSubMenu()}
              className="title-item"
            >
              {icon && (
                <Icon
                  fill={
                    active
                      ? theme.colors.accentPrimaryDark
                      : theme.colors.gray400
                  }
                  size={24}
                  src={icon}
                />
              )}
              <Text
                color={
                  active ? theme.colors.accentPrimaryDark : theme.colors.gray400
                }
              >
                {title}
              </Text>
              {!isCollapse && (
                <Icon
                  size={16}
                  src={ChevronUpIcon}
                  className="sub-item-arrow"
                />
              )}{' '}
            </button>
            <div className="sub-items">{children}</div>
          </>
        ) : (
          <>
            {icon && (
              <Icon
                fill={
                  active ? theme.colors.accentPrimaryDark : theme.colors.gray400
                }
                size={24}
                src={icon}
              />
            )}
            <Text
              color={
                active ? theme.colors.accentPrimaryDark : theme.colors.gray400
              }
            >
              {children}
            </Text>
          </>
        )}
      </Tooltip>
    </MenuItemStyle>
  );
};

MenuItem.propTypes = {
  icon: PropTypes.any,
  tooltipTitle: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  subItem: PropTypes.bool,
  title: PropTypes.string,
  secondaryMenu: PropTypes.bool,
};

MenuItem.defaultProps = {
  icon: '',
  tooltipTitle: '',
  children: '',
  active: false,
  subItem: false,
  title: '',
  secondaryMenu: false,
};

export default MenuItem;
