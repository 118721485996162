import { Container } from '@vli/locomotive-ds/dist';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from 'components/contexts/suppliers/Form';
import Loading from 'components/core/Loading';
import { Content } from 'components/system/interface';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { SupplierActions } from 'store/supplier/supplier.ducks';

import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Fornecedores',
    href: `#${ROUTES.SUPPLIER_LIST}`,
  },
  {
    text: 'Novo fornecedor',
    href: `#${ROUTES.NEW_SUPPLIER}`,
  },
];

const CreateSupplier = () => {
  const dimensions = useDimensions();

  const dispatch = useDispatch();

  const { supplierRequests } = useSelector(({ supplier }) => supplier);

  const onSubmit = (payload) => {
    dispatch(SupplierActions.createSupplier(payload));
  };

  const isLoading = isPending(supplierRequests.CREATE_SUPPLIER);

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        {isLoading && <Loading />}
        <Form breadcrumb={breadcrumb} onSubmit={onSubmit} />
      </Container>
    </Content>
  );
};

export default CreateSupplier;
