import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CheckboxIcon from 'assets/system/svgs/checkbox.svg';

import { IconSource } from './styles';

const Icon = ({ className, src, size, 'aria-label': label, ...attrs }) => {
  const classes = classNames(className);

  return (
    <IconSource
      src={src}
      width={`${size}px`}
      height={`${size}px`}
      className={classes}
      aria-label={label}
      data-testid="ds-icon-test"
      {...attrs}
    />
  );
};

Icon.propTypes = {
  src: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.number,
  'aria-label': PropTypes.string,
};

Icon.defaultProps = {
  src: CheckboxIcon,
  'aria-label': 'icon',
  size: 24,
  className: '',
};

export default Icon;
