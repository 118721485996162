import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import Flex from '../../core/flex';
import Pagination from '../../core/pagination';
import Select from '../../form/select';
import Text from '../../typography/text';

import { Container, Content } from './styles';

const Footer = ({
  totalItems,
  totalPages,
  currentPage,
  onPageChange,
  onChangePageSize,
  customList,
  ...attrs
}) => {
  const list = useMemo(() => {
    return [
      {
        selected: true,
        value: 25,
        label: '25 itens',
      },
      {
        value: 50,
        label: '50 itens',
      },
      {
        value: 100,
        label: '100 itens',
      },
      {
        value: 200,
        label: '200 itens',
      },
    ];
  }, []);

  const [options, setOptions] = useState();

  useEffect(() => {
    if (customList.length) {
      setOptions(customList);
    } else {
      setOptions(list);
    }
  }, [customList, list]);

  const updateOptions = (option) => {
    setOptions((oldOptions) =>
      oldOptions?.map(({ value, label }) =>
        option.value === value
          ? {
              selected: true,
              value,
              label,
            }
          : { value, label },
      ),
    );
  };

  return (
    <Container
      id="footer"
      flexDirection="row"
      alignItems="center"
      justifyContent={[
        'space-between',
        'space-between',
        'space-between',
        'center',
      ]}
      py="8px"
      data-testid="ds-footer"
      {...attrs}
    >
      {totalPages && (
        <Pagination
          type="numbered"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
          style={{ alignSelf: 'center' }}
        />
      )}
      <Flex
        display={['none', 'flex']}
        alignItems="center"
        position="absolute"
        right="16px"
      >
        <Content>
          <Text size="medium" display={['none', 'none', 'inline-flex']}>
            Exibindo
          </Text>
        </Content>
        <Select
          mx={2}
          height="32px"
          onChange={(size) => {
            updateOptions(size);
            onChangePageSize(size);
          }}
          options={options}
          isFooter
        />
        <Content>
          <Text size="medium" display={['none', 'none', 'inline-flex']}>
            de {totalItems} resultados
          </Text>
        </Content>
      </Flex>
    </Container>
  );
};

Footer.propTypes = {
  totalItems: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func,
  customList: PropTypes.array,
};

Footer.defaultProps = {
  totalItems: 0,
  onChangePageSize: () => {},
  customList: [],
};

export default Footer;
