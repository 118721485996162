import PropTypes from 'prop-types';
import React from 'react';

import ErrorImage from 'assets/system/svgs/errorImage.svg';
import Logo from 'assets/system/svgs/logo.svg';
import NotFoundImage from 'assets/system/svgs/notFoundImage.svg';

import Heading from '../../typography/heading';

import Icon from '../../core/icon';

import {
  CustomContainer,
  ErrorCodeStyle,
  ErrorContainerStyle,
  ErrorSvgStyle,
} from './styles';

const ErrorPainel = ({ children, code, customIcon, ...attrs }) => (
  <ErrorContainerStyle data-testid="ds-error" {...attrs}>
    <Icon src={Logo} />
    {customIcon ? (
      <CustomContainer>{customIcon()}</CustomContainer>
    ) : (
      <ErrorSvgStyle>
        {code === 404 ? (
          <Icon src={NotFoundImage} />
        ) : (
          <Icon src={ErrorImage} />
        )}
      </ErrorSvgStyle>
    )}
    <ErrorCodeStyle>
      <Heading tag="span">Error {code}</Heading>
    </ErrorCodeStyle>
    <div className="content">{children}</div>
  </ErrorContainerStyle>
);

ErrorPainel.propTypes = {
  children: PropTypes.node,
  code: PropTypes.number,
  customIcon: PropTypes.node,
};

ErrorPainel.defaultProps = {
  children: '',
  code: '',
  customIcon: '',
};

export default ErrorPainel;
