import { Container, Flex, Header } from '@vli/locomotive-ds/dist';
import ContractList from 'components/contexts/contract/ContractList/ContractList';
import { Content } from 'components/system/interface';
import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';
import React from 'react';

const Contract = () => {
  const dimensions = useDimensions();
  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
  ];

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Formalizações" />
        </Flex>
        <ContractList />
      </Container>
    </Content>
  );
};

export default Contract;
