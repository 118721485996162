import { Button, Container, Flex, Header } from '@vli/locomotive-ds/dist';
import SuppliersTable from 'components/contexts/suppliers/suppliersList/SuppliersTable';
import { Content } from 'components/system/interface';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';
import React from 'react';

const breadcrumb = [
  {
    text: 'Fornecedores',
    href: null,
  },
];

const SuppliersList = () => {
  const dimensions = useDimensions();

  const handleNavigateToCreateNewSupplier = () => {
    history.push(ROUTES.NEW_SUPPLIER);
  };

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Fornecedor" />

          <Button
            onClick={handleNavigateToCreateNewSupplier}
            name="Novo fornecedor"
            type="button"
            data-testid="new-button"
          />
        </Flex>

        <SuppliersTable />
      </Container>
    </Content>
  );
};

export default SuppliersList;
