import { Container, Flex, Header } from '@vli/locomotive-ds/dist';
import AccordionTemplateDownload from 'components/contexts/models/AccordionTemplateDownload';
import { Content } from 'components/system/interface';
import useDimensions from 'hooks/useDimensions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModelsActions } from 'store/model/model.ducks';

const breadcrumb = [
  {
    text: 'Gestão',
    href: null,
  },
  {
    text: 'Modelos',
    href: null,
  },
];

const Models = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const { templatesList } = useSelector((store) => store.model);

  useEffect(() => {
    dispatch(ModelsActions.templatesRequest());
  }, [dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Modelos" />
        </Flex>
        <Flex flexDirection="column">
          {templatesList.map((template) => (
            <AccordionTemplateDownload
              key={template.templateType}
              name={template.name}
              templateType={template.templateType}
              fileName={template.fileName}
            />
          ))}
        </Flex>
      </Container>
    </Content>
  );
};

export default Models;
