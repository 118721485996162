import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ChevronDownIcon from 'assets/system/svgs/chevron/down.svg';

import Icon from '../../core/icon';

import { SelectGroup, SelectGroupBox, SelectGroupDropdown } from './styles';

const Select = ({
  options,
  isFooter,
  topDropdown,
  placeholder,
  onChange,
  disabled,
  numTrunc,
  noBorder,
  selected,
  autocomplete,
  selectValue,
  ...attrs
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const selectRef = useRef(null);

  useEffect(() => {
    const selectedFiltered = options.filter((cur) => cur.selected)[0];

    if (selectedFiltered) {
      setSearch(selectedFiltered?.label);
      setSelectedOption(selectedFiltered);
    }
  }, [options]);

  useEffect(() => {
    if (typeof selected === 'object' && selected.value) {
      setSelectedOption(
        options.find(
          (option) => option.value === selected.value || option.selected,
        ),
      );
    } else if (typeof selected === 'number' || typeof selected === 'string') {
      setSelectedOption(
        options.find((option) => option.value === selected || option.selected),
      );
    }
  }, [selected, options]);

  useEffect(() => setOpen(false), [selectedOption]);

  useEffect(() => {
    const selectNode = selectRef.current;

    document.addEventListener('click', (e) => {
      if (e.target !== selectNode && !selectNode.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)}...`;
  };

  const handleChange = useCallback(
    (option) => {
      setSelectedOption(option);
      onChange(option);
      setSearch(truncateString(option?.label, numTrunc));
    },
    [numTrunc, onChange],
  );

  const renderOptions = useCallback(
    (currentOption) => {
      const includesSubString = (text) =>
        text.toLowerCase().includes(search.toLocaleLowerCase());

      const localOption =
        autocomplete && search?.length > 0
          ? currentOption.filter((option) => includesSubString(option.label))
          : currentOption;

      return (
        <ul>
          {localOption.map((option) => (
            <li
              className={
                selectedOption && selectedOption.value === option.value
                  ? 'active'
                  : ''
              }
              key={`select-${option.label}`}
            >
              <button
                data-testid={`ds-select-option-${option.label}`}
                type="button"
                title="Selecionar opção"
                aria-label={`Selecionar opção ${option.label}`}
                onClick={() => {
                  handleChange({
                    value: option.value,
                    label: option.label,
                  });
                }}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      );
    },
    [autocomplete, handleChange, search, selectedOption],
  );

  const renderOption = useCallback(() => {
    if (autocomplete) {
      return (
        <input
          type="text"
          value={search || selectValue}
          placeholder={placeholder}
          onChange={({ target: { value } }) => {
            setSearch(value);
            setOpen(true);
          }}
        />
      );
    }
    if (selectedOption) {
      return <span>{truncateString(selectedOption.label, numTrunc)}</span>;
    }

    return <span>{placeholder}</span>;
  }, [
    autocomplete,
    numTrunc,
    placeholder,
    search,
    selectedOption,
    selectValue,
  ]);

  return (
    <SelectGroup
      ref={selectRef}
      noBorder={noBorder}
      disabled={disabled}
      data-testid="ds-select"
      {...attrs}
    >
      <SelectGroupBox
        onClick={() => setOpen(!open)}
        disabled={disabled}
        type="button"
      >
        {renderOption()}
        <Icon src={ChevronDownIcon} />
      </SelectGroupBox>

      <SelectGroupDropdown
        isFooter={isFooter}
        topDropdown={topDropdown}
        active={open}
        disabled={disabled}
      >
        {renderOptions(options)}
      </SelectGroupDropdown>
    </SelectGroup>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    PropTypes.string,
    PropTypes.number,
  ]),
  isFooter: PropTypes.bool,
  topDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  numTrunc: PropTypes.number,
  noBorder: PropTypes.bool,
  autocomplete: PropTypes.bool,
  selectValue: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  selected: '',
  isFooter: false,
  topDropdown: false,
  onChange: () => {},
  disabled: false,
  placeholder: 'Selecione...',
  numTrunc: 70,
  noBorder: false,
  autocomplete: false,
  selectValue: '',
};

export default Select;
