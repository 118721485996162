import css from '@styled-system/css';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { color, compose, space } from 'styled-system';

export const IconSource = styled(ReactSVG)(
  ({ width }) => css({ width, path: { width } }),
  ({ height }) => css({ height, path: { height } }),
  ({ stroke }) => css({ path: { stroke } }),
  ({ fill }) => css({ path: { fill } }),
  compose(color, space),
);
