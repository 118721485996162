import css from '@styled-system/css';
import styled from 'styled-components';
import {
  color,
  compose,
  layout,
  space,
  typography,
  variant,
} from 'styled-system';

import theme from '../../tokens';

export const TextTag = styled('')(
  ({ size }) => {
    switch (size) {
      case 'medium':
        return css({ font: theme.fonts.textRegularMedium });
      case 'large':
        return css({ font: theme.fonts.textRegularLarge });
      default:
        return css({ font: theme.fonts.textRegularSmall });
    }
  },
  color({ color: color || theme.colors.gray500 }),
  variant({
    variants: {
      bold: {
        fontWeight: 'bold',
      },
    },
  }),
  compose(typography, color, space, layout),
);
