import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, space } from 'styled-system';

import theme from '../../tokens';

export const HeaderContainerStyle = styled('')(
  css({
    display: 'flex',
    alignItems: 'center',
    p: 16,
    '> :first-child': {
      m: 0,
      mr: 28,
      color: theme.colors.gray500,
    },
  }),
  compose(space),
);

export const HeaderActionsStyle = styled('div')(
  css({
    display: 'flex',
    alignItems: 'center',
    ml: 28,
  }),
  compose(space),
);
