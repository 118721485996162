import css from '@styled-system/css';
import styled from 'styled-components';
import { compose, layout, space } from 'styled-system';

import theme from '../../tokens';

export const SelectGroup = styled('div')(
  css({
    position: 'relative',
    width: 'auto',
    height: '40px',
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: '4px',
  }),
  ({ noBorder }) =>
    noBorder &&
    css({
      border: '1px solid #0000',
    }),
  ({ disabled }) =>
    disabled &&
    css({
      pointerEvents: 'none',
    }),
  compose(layout, space),
);

export const SelectGroupBox = styled('button')(
  css({
    backgroundColor: theme.colors.grayWhite,
    width: '100%',
    height: '100%',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    outline: 'none',
    padding: '0 10px 1px',
    transitionProperty: 'background-color',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    span: {
      color: theme.colors.gray500,
      font: theme.fonts.textRegularMedium,
      display: 'inline-block',
      pr: '20px',
      transitionProperty: 'color',
      transitionDuration: '.5s',
      transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    },
    input: {
      color: theme.colors.gray500,
      font: theme.fonts.textRegularMedium,
      display: 'inline-block',
      pr: '20px',
      transitionProperty: 'color',
      transitionDuration: '.5s',
      transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
      border: 'none',
    },
    svg: {
      path: {
        stroke: theme.colors.gray300,
        transitionProperty: 'stroke',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
      },
    },
  }),
  ({ disabled }) =>
    disabled &&
    css({
      backgroundColor: theme.colors.gray100,
      span: {
        color: theme.colors.gray300,
      },
      'svg path': {
        stroke: theme.colors.gray300,
      },
      input: {
        bg: theme.colors.gray100,
      },
    }),
);

export const SelectGroupDropdown = styled('div')(
  css({
    textSizeAdjust: 'none',
    backgroundColor: 'white',
    right: '0',
    left: '0',
    opacity: '0',
    pointerEvents: 'none',
    boxShadow:
      '0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    maxHeight: '200px',
    overflow: 'auto',
    zIndex: 2,
    transition: 'opacity .5s cubic-bezier(.5, 0, 0, 1)',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar:hover': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track:hover': {
      width: '8px',
    },
    '&:hover::-webkit-scrollbar-corner, :hover::-webkit-scrollbar-track': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '1px',
      background: theme.colors.accentPrimaryLight,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.accentPrimaryDark,
    },
    ul: {
      m: 0,
      p: 0,
      listStyle: 'none outside none',
      li: {
        position: 'realtive',
        width: '100%',
        display: 'block',
        '&.active button, :hover button, :focus button': {
          backgroundColor: theme.colors.accentPrimaryLight,
        },
        button: {
          backgroundColor: 'transparent',
          fontSize: 1,
          lineHeight: 1.4,
          textAlign: 'left',
          width: '100%',
          minHeight: '40px',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          padding: '0 10px 1px',
          transition: 'background-color .5s cubic-bezier(.5, 0, 0, 1)',
        },
      },
    },
    '&.omnisearch > ul > li > button > span': {
      color: theme.colors.gray400,
    },
  }),
  ({ active }) =>
    active &&
    css({
      opacity: '1',
      pointerEvents: 'initial',
    }),
  ({ isFooter }) =>
    isFooter
      ? css({
          position: 'relative',
          top: 'calc(100% + -224px)',
        })
      : css({
          position: 'absolute',
          top: 'calc(100% + 5px)',
        }),
  ({ topDropdown }) =>
    topDropdown &&
    css({
      top: 'unset',
      bottom: 'calc(100% + 5px)',
    }),
);
