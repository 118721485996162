import PropTypes from 'prop-types';
import React from 'react';

import { OpacityDivStyle } from './styles';

const OpacityDiv = ({ children, show, ...attrs }) => (
  <OpacityDivStyle
    className={show && 'show'}
    data-testid="ds-overlay"
    {...attrs}
  >
    {children}
  </OpacityDivStyle>
);

OpacityDiv.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

OpacityDiv.defaultProps = {
  show: false,
  children: '',
};

export default OpacityDiv;
