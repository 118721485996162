import PropTypes from 'prop-types';
import React from 'react';

import { ColStyle } from './styles';

const Col = ({ children, mobile, tablet, desktop, ...attrs }) => (
  <ColStyle
    mobile={mobile}
    tablet={tablet}
    desktop={desktop}
    data-testid="ds-col"
    {...attrs}
  >
    {children}
  </ColStyle>
);

Col.propTypes = {
  children: PropTypes.node,
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
};

Col.defaultProps = {
  children: '',
  mobile: '',
  tablet: '',
  desktop: '',
};

export default Col;
