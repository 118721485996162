import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import Route from 'constants/routes';

import { store } from 'store';

import { AuthModal } from 'components/core/AuthModal';
import MessageModal from 'components/core/MessageModal';
import MessageToast from 'components/core/MessageToast';

import GlobalStyle from 'components/system/styles/global';
import Tokens from 'components/system/tokens';

const App = ({ pca }) => (
  <Provider store={store}>
    <GlobalStyle />
    <ThemeProvider theme={Tokens}>
      <MsalProvider instance={pca}>
        <Route />
        <AuthModal />
      </MsalProvider>
    </ThemeProvider>
    <MessageToast />
    <MessageModal />
  </Provider>
);

export default App;
