import { Container, Header } from '@vli/locomotive-ds/dist';
import ContractReportList from 'components/contexts/contractReport/ContractReportList';
import Form from 'components/contexts/contractReport/Form';
import { Content } from 'components/system/interface';
import useDimensions from 'hooks/useDimensions';
import React from 'react';

const breadcrumb = [
  {
    text: 'Gestão contratual',
    href: `#`,
  },
];

const ContractReport = () => {
  const dimensions = useDimensions();

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Header bread={breadcrumb} tag="header" title="Gestão contratual" />
        <Form />
        <ContractReportList />
      </Container>
    </Content>
  );
};

export default ContractReport;
