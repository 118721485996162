import styled from 'styled-components';
import theme from '../../tokens';

const Content = styled.div`
  width: 100%;
  padding: 0px ${theme.spacing.sm};
  ${({ height }) => height && `height: ${height}px; overflow: auto;`}

  header {
    padding: 16px 0px;
  }
`;

export default Content;
