import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CloseIcon from 'assets/system/svgs/close.svg';
import HideMenuIcon from 'assets/system/svgs/hideMenu.svg';
import ShowMenuIcon from 'assets/system/svgs/showMenu.svg';

import MenuContext from './context';
import MenuHeader from './header';
import MenuWrapper from './wrapper';

import Icon from '../../core/icon';
import { MenuStyle, Span } from './styles';

const Menu = ({
  children,
  logo,
  brandTitle,
  collapse,
  position,
  navPosition,
  ...attrs
}) => {
  const [isCollapse, setCollapse] = useState(collapse);

  const toggleCollapse = useCallback(() => {
    setCollapse(!isCollapse);
  }, [isCollapse]);

  const closeMenu = () => {
    const menuDiv = document.getElementById('vl-menu');
    const bgOpacity = document.getElementById('vl-bg-opacity');
    menuDiv.classList.remove('show');
    bgOpacity.classList.remove('show');
  };

  const menuStyle = isCollapse ? 'collapse' : 'expanded';

  useEffect(() => {
    const profile = document.getElementById('profile');
    if (profile) {
      profile.addEventListener('click', () => {
        setCollapse(false);
      });
    }
  });

  const providerValue = useMemo(() => {
    return { toggleCollapse, isCollapse, closeMenu };
  }, [isCollapse, toggleCollapse]);

  return (
    <MenuContext.Provider value={providerValue}>
      <MenuWrapper
        data-testid="ds-menu"
        logo={logo}
        brandTitle={brandTitle}
        id="menu-wrapper"
        className={menuStyle}
        position={position}
      >
        <MenuStyle
          id="menu-container"
          className={menuStyle}
          navPosition={navPosition}
          {...attrs}
        >
          <MenuHeader
            id="menu-header"
            className={clsx('header', menuStyle)}
            logo={logo}
            brandTitle={brandTitle}
          >
            <h2>Menu</h2>
            <Span onClick={() => closeMenu()}>
              <Icon src={CloseIcon} />
            </Span>
          </MenuHeader>
          {children}
          <button
            type="button"
            id="hide-menu"
            className="hide-menu"
            onClick={() => toggleCollapse()}
          >
            {menuStyle !== 'collapse' ? (
              <Icon src={HideMenuIcon} />
            ) : (
              <Icon src={ShowMenuIcon} />
            )}
            <span>Esconder Menu</span>
          </button>
        </MenuStyle>
      </MenuWrapper>
    </MenuContext.Provider>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.any,
  brandTitle: PropTypes.string,
  collapse: PropTypes.bool,
  position: PropTypes.string,
  navPosition: PropTypes.string,
};

Menu.defaultProps = {
  children: '',
  logo: '',
  brandTitle: 'Centro de \n Inteligência',
  collapse: false,
  position: 'relative',
  navPosition: 'relative',
};

export default Menu;
