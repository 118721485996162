import styled from 'styled-components';
import theme from '../../tokens';

const getWidthGrid = (value) => {
  if (value) return '';
  const width = (value / 12) * 100;
  return `width: ${width}%;`;
};

export const ColStyle = styled.div`
  float: left;
  padding: ${theme.spacing.xss};
  min-height: 1px;
  box-sizing: border-box;
  ${theme.media.mobile} {
    ${({ mobile }) => mobile && getWidthGrid(mobile)}
  }
  ${theme.media.tablet} {
    ${({ tablet }) => tablet && getWidthGrid(tablet)}
  }
  ${theme.media.desktop} {
    ${({ desktop }) => desktop && getWidthGrid(desktop)}
  }
`;
