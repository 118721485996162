import { Button, Container, Flex, Header } from '@vli/locomotive-ds/dist';
import ReactivateItemsList from 'components/contexts/reactivateItems/ReactivateItemsList/ReactivateItemsList';
import { Content } from 'components/system/interface';
import * as ROUTES from 'constants/urls';
import useDimensions from 'hooks/useDimensions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ReactivateItemsActions } from 'store/reactivateItems/reactivateItems.ducks';

const breadcrumb = [
  {
    text: 'Reativar itens',
    href: `#${ROUTES.REACTIVATE_ITEMS}`,
  },
];

const ReactivateItems = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  const reactivateItems = () => {
    dispatch(ReactivateItemsActions.openReactivateItemModal(true));
  };

  useEffect(() => {
    dispatch(ReactivateItemsActions.reactivateItemsListRequest(''));
  }, [dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Reativar itens" />
          <Button name="Reativar seleção" onClick={reactivateItems} />
        </Flex>
        <ReactivateItemsList />
      </Container>
    </Content>
  );
};

export default ReactivateItems;
